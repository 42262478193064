.sidebar {
  width: 200px;
  background: #c0dbfd;
  color: white;
  height: 100vh;
  /* height: calc(100vh - 50px);  */
  padding: 10px;
  position: fixed;
  top: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  transition: width 0.3s ease, background-color 0.4s ease;
  overflow-y: auto;
  z-index: 10;
}

.sidebar.closed {
  width: 60px;
}

.sidebar-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 15px;
  transition: color 0.3s ease;
}

.sidebar-toggle-icon {
  font-size: 24px;
  color: rgb(22, 106, 234);
  transition: color 0.3s ease;
  justify-content: center;
}

.sidebar-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}

.sidebar-item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 10px;
  background: linear-gradient(135deg, #cadcfe, #ffffff);
  border-radius: 12px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  margin: 10px 0;
}

.sidebar-item-bottom {
  display: flex;
  align-items: center;
  padding: 10px 95px 10px 10px;
  background: linear-gradient(135deg, #cadcfe, #ffffff);
  border-radius: 12px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  justify-content: center;
  bottom: 0px;
  margin: 10px 0px;
}

.sidebar.closed .sidebar-item-bottom {
  padding: 10px 5px 10px 10px;
}

.sidebar-item .icon,
.sidebar-item-bottom .icon {
  /* font-size: 18px; */
  margin-right: 10px;
  color: black;
  transition: transform 0.3s ease, color 0.3s ease;
}

.sidebar-item span,
.sidebar-item-bottom span {
  font-size: 15px;
  font-weight: 500;
  color: black;
}

.sidebar-item:hover .icon,
.sidebar-item-bottom:hover .icon {
  transform: scale(1.2);
}

.sidebar-item:hover,
.sidebar-item-bottom:hover {
  transform: scale(1.01);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.sidebar-sub-item {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.sidebar-sub-item.row {
  flex-direction: row;
  gap: 0.5rem;
}

.sidebar-sub-item.column {
  flex-direction: column;
  gap: 0.2rem;
}

.sidebar-sub-item .icon {
  font-size: 1.3rem;
  /* margin-left:0.5rem; */
  /* margin-right: -15px; */
}

.token-count {
  font-size: 1rem;
  margin-left: -0.5rem;
  color: black;
}

/* Topbar for Small Devices */
.topbar {
  display: none;
  position: fixed;
  top: 43px;
  left: 0;
  width: 100%;
  background: linear-gradient(135deg, #1c7ed6, #0072ff);
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: background 0.3s ease;
}

.topbar-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #ffffff;
  height: 30px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.topbar-toggle:hover {
  transform: scale(1.05); /* Slight zoom effect */
}

.topbar-toggle-icon {
  font-size: 24px;
  color: #ffffff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.topbar-content {
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  background-color: #ffffff; /* Clean white background for topbar items */
  color: #444;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for elegance */
}

.topbar-item {
  display: flex;
  align-items: center;
  padding: 3px;
  margin: 5px 0;
  border-radius: 8px; /* Rounded corners for a modern look */
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.topbar-item .icon {
  margin-right: 10px;
  font-size: 20px;
  color: rgb(22, 106, 234); /* Matching the primary color of the topbar */
}

.topbar-item span {
  font-size: 18px;
  color: #444;
}

.topbar-item:hover {
  background-color: #edf1ff; /* Light blue background on hover */
  box-shadow: 0 2px 8px rgba(90, 133, 255, 0.3); /* Shadow effect on hover */
  transform: scale(1.02); /* Slight zoom on hover */
}
/* Token Display in Topbar */
.topbar-sub-item {
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  align-items: center;
  margin: 10px 0;
  padding: 8px;
  background-color: #edf1ff; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(90, 133, 255, 0.3); /* Soft shadow */
}

.topbar-sub-item .icon {
  font-size: 1.5rem; /* Icon size */
  margin: 0 8px; /* Spacing between icons */
}
.topbar-sub-item span {
  font-size: 18px;
}
.text-icon {
  font-weight: bold !important;
  margin-right: 8px;
  font-size: 17px !important;
}
.topbar-text-icon {
  font-weight: bold !important;
  margin-right: 8px;
  margin-left: 5px;
  font-size: 20px !important;
  color: rgb(22, 106, 234) !important;
}

.fire-icon {
  width: 20px;
  height: auto; /* Maintain aspect ratio */
  display: inline-block;
  margin-right: 5px;
}
.credit-icon {
  width: 26px;
  height: auto; /* Maintain aspect ratio */
  display: inline-block;
  margin-right: 5px;
}

@media (max-width: 1024px) {
  .sidebar {
    width: 60px;
  }

  .sidebar.open {
    width: 200px;
  }
  .sidebar-item-bottom {
    padding: 10px 95px 10px 10px;
  }
}

/* For mobile devices (portrait) */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .topbar {
    display: block;
  }
}

/* For very small screens (less than 480px) */
@media (max-width: 480px) {
  .topbar-item {
    font-size: 14px;
  }

  .topbar-item .icon {
    font-size: 18px;
  }
  .topbar-item span {
    font-size: 14px;
    color: #444;
  }
  .topbar-sub-item {
    padding: 5px;
  }
  .topbar-sub-item .icon {
    font-size: 1.2rem;
  }
  .topbar-sub-item span {
    font-size: 16px;
  }
}

/* hoverexplain */
.tooltip {
  position: fixed;
  bottom: auto; /* Remove the bottom positioning */
  top: calc(15% - 30px);
  left: 150px; /* Adjust to align with your sidebar */
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none; /* Prevent blocking mouse events */
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.sidebar-item:hover .tooltip {
  opacity: 1;
}

.sidebar-item {
  position: relative;
}

.sidebar-bottom {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
}

.about-us-icon {
  width: 40px;
}

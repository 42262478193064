.chatDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 30px);
  background-color: #f5f5f5;
}

.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60%;
  width: 100%;
  margin: 0px auto;
}

.welcome-message {
  text-align: center;
  margin-bottom: 15px;
}

.welcome-message h2 {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.welcome-message p {
  font-size: 14px;
  color: #666;
  margin: 5px 0 0;
}

.chat-messages {
  width: 100%;
  height: calc(100vh - 210px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 40px;
}

.message.user {
  align-self: flex-end;
  background: #0084ff;
  color: white;
  padding: 10px;
  border-radius: 15px;
  max-width: 80%;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.message.ai {
  align-self: flex-start;
  background: #e0e0e0;
  color: #333;
  padding: 10px;
  border-radius: 15px;
  max-width: 80%;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.file-name-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 98%;
  margin-bottom: 10px;
}

.chat-div-open {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 30px);
  background-color: #f5f5f5;
  width: calc(100% - 200px);
  margin-left: auto;
}

.chat-container-open {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 75%;
  width: 100%;
  margin: 0px auto;
}

.copy-button,
.edit-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #555;
  transition: color 0.3s ease;
}

.copy-button:hover,
.edit-button:hover {
  color: #222;
}

.chat-box {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 50px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  gap: 8px;
  width: 100%;
}

.file-upload {
  background: #e0e0e0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.file-upload .icon {
  font-size: 18px;
  color: #333;
}

.chat-input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 25px;
}

.voice-button {
  background: #ff9800;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s ease;
}

.voice-button:hover {
  background: #e68900;
}

.send-button {
  background: #0084ff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s ease;
}

.send-button:hover {
  background: #0084ff;
}

.menu-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 8px 10px 8px 20px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 0;
  align-self: flex-start;
  background-color: #e0e0e0;
  border-radius: 8px;
  margin-top: auto;
  font-weight: 600;
}

.remove-file {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 16px;
  color: brown;
}

.chat-input:disabled,
.voice-button:disabled,
.send-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.file-warning {
  text-align: center;
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

.options-menu {
  position: absolute;
  top: -105px;
  bottom: 0px;
  left: 0px;
  width: 300px;
  height: 100px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.option {
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
}

.option:hover {
  background: #f3dada;
}

.voice-button {
  position: relative;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  color: #555;
  transition: color 0.3s ease;
}

.voice-button.active {
  color: red;
}

.voice-button.active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background: rgba(255, 0, 0, 0.4);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: wave 1.5s infinite;
}

.voice-button.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  background: rgba(255, 0, 0, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: wave 1.5s infinite 0.5s;
}

@keyframes wave {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.8);
    opacity: 0;
  }
}

/* Mobile Optimization */
@media (max-width: 480px) {
  .chat-container {
    max-width: 95%;
    padding: 10px;
  }

  .chat-header {
    font-size: 14px;
    padding: 10px;
  }

  .upload-box {
    max-width: 100%;
    padding: 15px;
  }

  .voice-button,
  .send-button {
    width: 40px;
    height: 40px;
    font-size: 16px;
    padding: 11px;
  }

  .error-message {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .chat-container {
    max-width: 95%;
    padding: 10px;
  }

  .chat-messages {
    height: calc(100vh - 180px);
  }

  .message {
    max-width: 90%;
    font-size: 14px;
  }

  .chat-input {
    font-size: 14px;
  }

  .voice-button,
  .send-button {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .chat-container {
    max-width: 100%;
    padding: 5px;
  }

  .chat-messages {
    height: calc(100vh - 160px);
  }

  .message {
    max-width: 95%;
    font-size: 12px;
  }

  .chat-input {
    font-size: 12px;
  }

  .voice-button,
  .send-button {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
}

@media (max-width: 300px) {
  .chat-container {
    max-width: 100%;
    padding: 5px;
  }

  .welcome-message h2 {
    font-size: 12px;
  }

  .welcome-message p {
    font-size: 10px;
  }

  .chat-messages {
    height: calc(100vh - 160px);
    padding: 4px;
  }

  .message.user,
  .message.ai {
    font-size: 10px;
    padding: 4px;
    max-width: 80%;
  }

  .chat-box {
    padding: 5px;
    gap: 4px;
  }

  .chat-input {
    font-size: 10px;
    padding: 4px;
  }

  .voice-button,
  .send-button {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }
}

.version-switch-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.version-switch-btn {
  background-color: #0a1f44;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.version-switch-btn:hover {
  background-color: #05122e;
}

.feature-label {
  color: #007bff;
  font-weight: 500;
  font-size: 14px;
  /* border-bottom: 2px solid #007bff; */
  padding-bottom: 3px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.feature-label:hover {
  color: #0056b3;
  border-bottom-color: #0056b3;
}
